import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import Seo from "../components/seo";
import * as styles from "../styles/richText.module.scss";

export const query = graphql`
query richTextPage($richTextPageId: DirectusData_GraphQLStringOrFloat){
  directus {
    richTextPages(filter: {id: {_eq: $richTextPageId}}) {
      id
      bannerImage {
        id
        description
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      content
      seoDescription
      seoImage {
        id
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      path
      title
    }
  }
}
`;

export default function RichTextTemplate({ data }) {
  var pageData = data.directus.richTextPages[0];

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <link rel="icon" type="image/png" href="/favicon.png" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Nunito+Sans"
        />
      </Helmet>
      <Seo
        title={pageData.title}
        description={pageData?.seoDescription}
        image={pageData?.seoImage?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}/>
      <div className="layout">
        <Header />
        <main>
          <div className={styles.richTextContainer}>
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>{pageData.title}</h1>
            </div>
            <img src={pageData?.bannerImage?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} alt={pageData?.bannerImage?.description}/>
            <div className={styles.contentContainer}>
              <div dangerouslySetInnerHTML={{ __html: pageData.content }}></div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

